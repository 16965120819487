// AuthForm styles
@import "styles/mixins.module.sass"

.authForm
	+flex-column
	flex-grow: 1
	justify-content: center
	max-width: 23.25rem
	padding: 1rem
	width: 100%

	> button,
	input
		&:focus-visible
			outline: revert

	h4
		font-size: 2rem
		padding-bottom: 0.5rem

		+md
			font-size: 2.5rem

.subtitle
	padding-bottom: 2.5rem

.titleLogo
	margin-top: 2rem
	margin-bottom: 2.5rem

	img
		width: 9rem

	+md
		display: none

.authDivider
	+flex-row-center
	padding: 1.5rem 0 1rem 0
	width: 100%

	span
		padding: 0 1.25rem

.loadingContainer
	+flex-column-center
	width: stretch
	min-height: 300px

.forgotPassword
	font-weight: 700
	font-size: 1rem
	margin-bottom: 1rem
	text-decoration-line: underline
	width: max-content

	&:hover
		cursor: pointer

.footerContent
	+flex-column-center
	padding-top: 2rem

.toggleText
	padding-top: 3rem
