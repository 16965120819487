// Input
@import "styles/mixins.module.sass"

.wrapper
	+field-wrapper
	padding-bottom: 0.5rem

.label
	+VG5000
	font-size: 1.375rem
	font-style: normal
	line-height: 1.625
	padding-bottom: 0.5rem

.labelNoMargin
	padding-bottom: 0

.sublabel
	+DM-Sans
	font-size: 1rem
	line-height: 1.5
	padding-bottom: 1rem

.inputWrapper
	align-items: center
	display: flex
	position: relative
	width: 100%

.input
	+form-input

.inputError
	background: $orange30
	border-color: $orange

.inputWithIcon
	padding-left: 2rem

.errorText
	+text-size-small
	color: $orange
	padding-bottom: 0.5rem
