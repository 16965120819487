// BrandList styles
@import "styles/mixins.module.sass"

.brands
  align-items: center
  display: flex
  flex-direction: column
  gap: 0.5rem

  p
    +badge-small

.brandList
  align-items: center
  display: flex
  flex-wrap: wrap
  gap: 0.75rem
  justify-content: center

  img
    max-height: 1.25rem

    &:first-child
      max-height: 1rem

    &:nth-child(2)
      max-height: 1rem
