// Toast styles
@import "styles/mixins.module.sass"

$Duration: 1.5s
$TravelDistance: 5vh

.gui-toast-group
    position: fixed
    z-index: $ModalZIndex + 5
    inset-block-start: 0.5rem
    inset-inline: 0
    padding-block-end: 5vh
    display: grid
    justify-items: center
    justify-content: center
    gap: 1vh
    pointer-events: none

.gui-toast
    color: $white
    background: $black
    max-inline-size: min(25ch, 90vw)
    padding: 0.5rem 1rem
    border-radius: 4px
    will-change: transform
    animation: fade-in .3s ease, slide-in .3s ease, fade-out .3s ease $Duration
    @keyframes fade-in
        from
            opacity: 0
    @keyframes fade-out
        to
            opacity: 0
    @keyframes slide-in
        from
            transform: translateY($TravelDistance, 10px)
