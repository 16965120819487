@import "styles/mixins.module"

.authView
	+flex-row
	background: $yellow30
	height: 100%
	max-width: 100%
	overflow: hidden
	width: 100%

.leftColumn
	display: none

	+md
		align-items: center
		background-color: $bubbletape-pink-10
		display: flex
		flex-direction: column
		gap: 4rem
		max-height: 100dvh
		overflow: hidden
		padding: 0 1rem
		width: 50%

		.logo
			display: block
			margin: 4rem auto 0 auto

		.contentImgWrapper
			align-items: center
			display: flex
			justify-content: center
			flex-grow: 1

		.contentImg
			max-height: 70rem
			max-width: 45rem
			object-fit: contain
			width: 100%

.authContainer
	+flex-column
	align-items: center
	height: 100%
	max-height: 100dvh
	min-height: 100dvh
	overflow-y: auto
	position: relative
	width: 100%

	+md
		width: 50%

.footer
	+flex-column-center
	background: $daisy-yellow-30
	border-top: 0.0625rem solid $gray30
	bottom: 0
	gap: 0.75rem
	min-height: 4.5rem
	padding: 1rem
	position: sticky
	width: 100%

	p
		+badge-small
		font-size: 0.75rem

	a
		+DM-Sans
		font-weight: 700
